var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.selection,
            title: "快速品牌管理",
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selection = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "row-bg",
                          attrs: { type: "flex", justify: "space-between" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleredit },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: { click: _vm.handlerrom },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            stripe: "",
                            data: _vm.tableData,
                            height: "400px",
                            "row-class-name": _vm.tableRowClassName,
                          },
                          on: { "row-click": _vm.routerClass },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "行业",
                              prop: "name",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: { click: _vm.handleraddhangye },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleraddp },
                        },
                        [_vm._v("添加")]
                      ),
                      _c("br"),
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.listData } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "行号",
                              align: "center",
                              type: "index",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "排序", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "80px" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.handlerpaixu(row)
                                        },
                                      },
                                      model: {
                                        value: row.sort,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sort", $$v)
                                        },
                                        expression: "row.sort",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "品牌", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "220px" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.handlerpaixu(row)
                                        },
                                      },
                                      model: {
                                        value: row.brand_name,
                                        callback: function ($$v) {
                                          _vm.$set(row, "brand_name", $$v)
                                        },
                                        expression: "row.brand_name",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerdelete(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "row-bg",
                          attrs: { type: "flex", justify: "end" },
                        },
                        [
                          _c("el-pagination", {
                            attrs: {
                              background: "",
                              "current-page": _vm.form.pageNo,
                              "page-sizes": _vm.pageSizes,
                              "page-size": _vm.form.pageSize,
                              layout: _vm.layout,
                              total: _vm.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                visible: _vm.editshow,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editshow = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { data: _vm.editform, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "行业：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editform.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "name", $$v)
                          },
                          expression: "editform.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerhanye },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                visible: _vm.pinpaishow,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.pinpaishow = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { data: _vm.pinform, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称", prop: "brand_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.pinform.brand_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.pinform, "brand_name", $$v)
                          },
                          expression: "pinform.brand_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌序号", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.pinform.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.pinform, "sort", $$v)
                          },
                          expression: "pinform.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerpinp },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selection = !_vm.selection
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }