<!-- 快速选品管理 -->
<template>
  <div class="orderTest-container">
    <el-dialog
      :visible.sync="selection"
      title="快速品牌管理"
      center
      width="80%"
    >
      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-button type="primary" @click="handleredit">编辑</el-button>
              <el-button type="warning" @click="handlerrom">删除</el-button>
            </el-row>
            <br />
            <el-table
              stripe
              :data="tableData"
              height="400px"
              :row-class-name="tableRowClassName"
              @row-click="routerClass"
            >
              <el-table-column
                label="行业"
                prop="name"
                align="center"
              ></el-table-column>
            </el-table>
            <br />
            <el-button type="warning" @click="handleraddhangye">添加</el-button>
          </el-col>
          <el-col :span="18">
            <el-button type="primary" @click="handleraddp">添加</el-button>
            <br />
            <el-table stripe :data="listData">
              <el-table-column
                label="行号"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column label="排序" align="center">
                <template #default="{ row }">
                  <el-input
                    v-model="row.sort"
                    style="width: 80px"
                    @blur="handlerpaixu(row)"
                  />
                </template>
              </el-table-column>
              <el-table-column label="品牌" align="center">
                <template #default="{ row }">
                  <el-input
                    v-model="row.brand_name"
                    style="width: 220px"
                    @blur="handlerpaixu(row)"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120">
                <template #default="{ row }">
                  <el-button type="text" @click="handlerdelete(row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row type="flex" class="row-bg" justify="end">
              <el-pagination
                background
                :current-page="form.pageNo"
                :page-sizes="pageSizes"
                :page-size="form.pageSize"
                :layout="layout"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-dialog width="30%" :visible.sync="editshow" append-to-body>
        <el-form :data="editform" inline>
          <el-form-item label="行业：">
            <el-input v-model="editform.name" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handlerhanye">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog width="30%" :visible.sync="pinpaishow" append-to-body>
        <el-form ref="form" :data="pinform" inline>
          <el-form-item label="品牌名称" prop="brand_name">
            <el-input v-model="pinform.brand_name" />
          </el-form-item>
          <el-form-item label="品牌序号" prop="sort">
            <el-input v-model="pinform.sort" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handlerpinp">确定</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selection = !selection">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import pagination from '@/mixins/pagination'
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    mixins: [pagination],
    data() {
      return {
        editshow: false,
        selection: false,
        pinpaishow: false,
        tableData: [],
        listData: [],
        tableClass: 0,
        pinform: {
          brand_name: '', //名称
          type_id: '', //行业id
          sort: '', //排序
        },
        editform: {
          name: '', //名称
          id: '', //行业id
        },
        form: {
          pageNo: 1,
          pageSize: 10,
          type_id: '',
          name: '',
        },
        url: {
          brand: '/superAdmin/brand/quick-type',
          save: '/superAdmin/brand/index',
        },
      }
    },

    computed: {},
    watch: {
      selection(val) {
        if (!val) {
          this.$emit('getlist')
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleraddp() {
        this.pinpaishow = true
        this.pinform.type_id = this.form.type_id
      },
      handlerpinp() {
        console.log(this.pinform)
        postAction('/superAdmin/brand/create', this.pinform)
          .then((res) => {
            console.log('res', res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.pinpaishow = false
            this.pinform = {
              brand_name: '', //名称
              type_id: '', //行业id
              sort: '', //排序
            }
            this.form.pageNo = 1
            this.handlerlist()
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      handlerpaixu(row) {
        console.log(row)
        var data = {
          id: row.id,
          brand_name: row.brand_name,
          sort: row.sort,
        }
        postAction('/superAdmin/brand/update', data)
          .then((res) => {
            console.log('res', res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.editshow = false
            this.form.pageNo = 1
            // debugger
            this.handlerlist()
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      handleraddhangye() {
        this.editform.id = ''
        this.editshow = true
      },
      handlerrom() {
        this.editform.name = this.form.name
        this.editform.id = this.form.type_id
        postAction('/superAdmin/brand/quick-del', this.editform)
          .then((res) => {
            console.log('res', res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.editshow = false
            this.handlerbrand()
            this.editform = {
              name: '', //名称
              id: '', //行业id
            }
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      handlerhanye() {
        console.log(this.editform)
        if (this.editform.id != '') {
          postAction('/superAdmin/brand/quick-edit', this.editform)
            .then((res) => {
              console.log('res', res)
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.editshow = false
              this.handlerbrand()
              this.editform = {
                name: '', //名称
                id: '', //行业id
              }
            })
            .catch((err) => {
              console.log('err', err)
            })
        } else {
          postAction('/superAdmin/brand/quick-add', this.editform)
            .then((res) => {
              console.log('res', res)
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.editshow = false
              this.handlerbrand()
              this.editform = {
                name: '', //名称
                id: '', //行业id
              }
            })
            .catch((err) => {
              console.log('err', err)
            })
        }
      },
      handleredit() {
        this.editform.name = this.form.name
        this.editform.id = this.form.type_id
        this.editshow = true
      },
      handlerdelete(row) {
        console.log(row)
        postAction('/superAdmin/brand/delete', { id: row.id })
          .then((res) => {
            console.log('res', res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.form.pageNo = 1
            this.handlerlist()
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      handlerbrand() {
        postAction(this.url.brand, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.form.type_id = this.tableData[0].id
            this.form.name = this.tableData[0].name
            this.form.pageNo = 1
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerlist() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log('res', res)
            this.listData = res.data.rows
            this.total = Number(res.data.total)
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      routerClass(row) {
        console.log(row, '样式')
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].id == row.id) {
            this.tableClass = i
            this.form.type_id = row.id
            this.form.name = row.name
          }
        }
        this.form.pageNo = 1
        this.handlerlist()
        this.value = ''
      },
      tableRowClassName({ rowIndex }) {
        if (rowIndex === this.tableClass) {
          return 'warning-row'
        } else {
          return 'success-row'
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-table .warning-row {
    color: #ffffff;
    background: #1890ff;
  }

  .el-table.success-row {
    background: #ffffff;
  }
</style>
