// 将分页的公用代码抽取出来 规定查询数据的方法名为handlerlist,页面中统一调用
//统一命名规则查询时放置的数据要以form命名，
//混入的一些注意事项
// 1，使用混入是data里的数据优先使用组件页面的
// 2，使用混入时钩子函数的同名函数会合并成一个数组都会被调用且混入的钩子优先调用
// 3,components,methods,directives等对象，会被合并为同一对象。如果出现了相同，则组件自己中的优先执行
export default {
  data() {
    return {
      total: 0, // 总记录数
      layout: 'total, sizes, prev, pager, next, jumper', // 控件
      pageSizes: [10, 20, 50, 100], // 页容量规格
      form: {
        pageNo: 1, // 当前页码
        pageSize: 10, // 页容量
      },
    }
  },
  methods: {
    // 当前页容量发生改变
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.form.pageSize = val
      this.handlerlist()
    },
    // 当前页码发生改变
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.form.pageNo = val
      this.handlerlist()
    },
    //获取页面数据列表的方法
    //建议写到页面上不建议采用混入的方式可能后台返回来的数据格式不一样导致采用混入会没有数据
    // handlerlist() {
    //   postAction(this.url.save, this.form)
    //     .then((res) => {
    //       console.log('res', res)
    //       this.tableData = res.data
    //       this.total = res.totalCount
    //     })
    //     .catch((err) => {
    //       console.log('err', err)
    //     })
    // },
  },
}

//页面用法
/* <el-row type="flex" class="row-bg" justify="end">
<el-pagination
  background
  :current-page="form.pageNo"
  :page-sizes="pageSizes"
  :page-size="form.pageSize"
  :layout="layout"
  :total="total"
  @size-change="handleSizeChange"
  @current-change="handleCurrentChange"
></el-pagination>
</el-row> */
